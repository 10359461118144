<template>
  <div class="proximo-partido">
    <router-link :to="'match_stats/'+match.id">


    <div class="proximo-partido__title"></div>
    <div class="proximo-partido__content mx-10 my-10">
      <div class="flex font-sans ">

        <form class="flex-auto p-6">
          <div class="flex flex-wrap justify-start">
            <h1 class="flex-auto text-lg font-semibold text-slate-900">
              {{ match.local_team.name }}
            </h1>
            <div class="flex-auto w-10 place-self-center text-lg font-semibold text-slate-500">
              ⚽
            </div>
            <h1 class="flex-end text-lg font-semibold text-slate-900">
              {{ match.visitor_team.name }}
            </h1>
            <div class="w-full flex-none text-sm font-medium text-slate-700 mt-2">
              {{ readableDayAndHour }}
            </div>
          </div>

        </form>
      </div>
    </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'proximo-partido',
  props: ['match'],
  mixins: [],
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
  computed: {
    readableDayAndHour(){
      // match.day readable formated in javascript
      let date = new Date(this.match.day)
      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };

      const formatter = new Intl.DateTimeFormat('es-ES', options);
      const formattedDate = formatter.format(date);
      return  this.match.hour + " - "+formattedDate
    }
  }
}
</script>
<style lang="scss">
.proximo-partido {

  &__title {

  }

  &__content {
    background-color: theme('colors.white');
    border-radius: theme('borderRadius.lg');
    padding: theme('spacing.6');
    box-shadow: theme('boxShadow.xl');
    //background: linear-gradient(to bottom, rgba(0, 220, 13, 0.20) 0%, #fff 50%, #ff000020 100%);
    //border-radius: 10px;

  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .proximo-partido {
    &__title {

    }

    &__content {

    }
  }
}

</style>

