<template>
  <div class="proximos-partidos">
    <proximos-partidos-titulo/>
    <div class="proximos-partidos__title"></div>
    <div class="proximos-partidos__content">
      <proximo-partido v-for="match in nextMatches" :key="match.id" :match="match"/>
    </div>
  </div>
</template>

<script>
import ProximoPartido from "@/components/ProximosPartidos/ProximoPartido.vue";
import ProximosPartidosTitulo from "@/components/ProximosPartidos/ProximosPartidosTitulo.vue";

export default {
  name: 'proximos-partidos',
  components: {ProximosPartidosTitulo, ProximoPartido},
  props: [],
  mixins: [],
  data() {
    return {
      nextMatches:[]
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(){
      this.$vumonAxios('get','upcoming_matches_with_data').then(response =>{
        this.nextMatches = response.data
      })
    }
  },
  computed: {}
}
</script>
<style lang="scss">
.proximos-partidos {

  &__title {

  }

  &__content {

  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .proximos-partidos {
    &__title {

    }

    &__content {

    }
  }
}

</style>

