<template>
  <div>
    <div class="bg-sky-950 lg:flex lg:items-center lg:justify-between">
      <div class="min-w-0 flex-1 p-10">
        <h2 class="text-2xl font-bold leading-7 text-gray-100 sm:truncate sm:text-3xl sm:tracking-tight">
          Próximos Partidos</h2>
        <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div class="mt-2 flex items-center text-sm text-gray-300">
            🇲🇽
            LigaMX
          </div>

          <div class="mt-2 flex items-center text-sm text-gray-300">
            <svg class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-300" viewBox="0 0 20 20" fill="currentColor"
                 aria-hidden="true">
              <path fill-rule="evenodd"
                    d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
                    clip-rule="evenodd"/>
            </svg>
            {{ todayDate }}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: 'proximos-partidos-titulo',
  computed:{
    todayDate(){
      const currentDate = new Date();

      const options = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };

      const formatter = new Intl.DateTimeFormat('es-ES', options);
      const formattedDate = formatter.format(currentDate);
      return formattedDate
    }
  }
}
</script>
<style lang="scss">

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

}

</style>
