import store from '../store'

const Rights = {

    install (Vue, options) {
        // 4. add an instance method
        Vue.prototype.$rights = function (ability) {
            // si la ability esta a la llista de rights retornem true.
            if (store.getters.getRights.indexOf(ability) >= 0) {
                return true
            }
            return false
        }
    }
}

export default Rights
