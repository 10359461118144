import store from '../store'
import router from '../router'
import axios from 'axios'
import apiUrl from '../apiUrl'

const vumonAxios = {

    install (Vue, options) {
        // 4. add an instance method
        Vue.prototype.$vumonAxios = function (type, uri, body = null, headers = {}, url = null) {
            return new Promise(function (resolve, reject) {
                // do a thing, possibly async, then…
                // check if uri has a trailing slash
                if (uri.slice(-1) == '/' || uri.indexOf('/?') > 0) {
                    console.error('Vumon Error: Please correct this url ' + uri + ' and remove the last slash "/" it will not work in production due to https cross origin problems with the api.')
                }

                const cacheHeaders = {
                    'Cache-Control': 'no-store, no-cache',
                    Pragma: 'no-cache'
                }

                const localHeaders = {
                    headers: cacheHeaders,
                    method: type,
                    url: apiUrl() + uri
                }
                let mergedHeaders = Object.assign({}, localHeaders, headers)

                if (body != null) {
                    mergedHeaders.data = body
                }
                Vue.prototype.$loadingOn()
                axios(mergedHeaders)
                    .then(response => {
                        // Vue.prototype.$loading().close()
                        Vue.prototype.$loadingOff()
                        // Vue.prototype.$showToast(response.data.message, 'success', 500)
                        resolve(response)
                    }).catch(e => {
                    let message = ''
                    Vue.prototype.$loadingOff()
                    if (e.response.status == 403) {
                        message = 'Login erroneo'
                        store.dispatch('logout').then(() => {
                            router.push('/')
                        })
                    } else if (e.response.status == 500) {
                        message = 'Problema con el servidor'
                    } else if (e.response.status == 404) {
                        message = 'No encontrado'
                    } else {
                        message = 'Problemas con internet'
                    }
                    // Vue.prototype.$loading().close()


                    reject(e)
                })
                    .finally(() => {
                        Vue.prototype.$loadingClear()
                    })
            })
        }
    }
}

export default vumonAxios
