const state = {
    loading: 0,
    petitions: 0
};

const actions = {



    incrementLoading({ commit }) {
        commit("incrementLoadingMutation");
    },
    decrementLoading({ commit }) {
        commit("decrementLoadingMutation");
    },
    clearLoading({ commit }) {
        commit("clearLoadingMutation");
    },
    incrementPetitions({ commit }) {
        commit("incrementPetitionsM");
    },
    decrementPetitions({ commit }) {
        commit("decrementPetitionsM");
    }
};

const mutations = {
    incrementLoadingMutation(state) {
        state.loading++;
    },
    decrementLoadingMutation(state) {
        if (state.loading > 0) {
            state.loading--;
        }
    },
    clearLoadingMutation(state) {
        state.loading = 0;
    },
    incrementPetitionsM(state) {
        state.petitions++;
    },
    decrementPetitionsM(state) {
        if (state.petitions > 0) {
            state.petitions--;
        }
    },
    logoutLoading(state) {
        state.loading = 0;
        state.petitions = 0;
    }
};

const getters = {
    getLoading: state => {
        return state.loading > 0;
    },
    getPetitions: state => {
        return state.petitions > 0;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
