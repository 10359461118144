import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

const files = require.context('../store', true, /\.js$/)

let modules = []
files.keys().forEach(key => {

  //ignore itself
  if (key === './index.js') return

  //Add the store files
  modules.push(files(key).default)
})

Vue.use(Vuex)
export default new Vuex.Store({
  modules,
  plugins: [createPersistedState()]
})


