const TextHelper = {

    install(Vue, options) {
        // 4. add an instance method
        Vue.prototype.$textHelper = function (str) {

            if (!str) {
                return ''
            }
            if (typeof str == 'undefined') {
                return ''
            }
            if (str.length == 0) {
                return ''
            }

            return str.toLowerCase().split(' ').map(function (word) {
                if (word.length == 0){
                    return ''
                }
                return word.replace(word[0], word[0].toUpperCase());
            }).join(' ');
        }

    }
}

export default TextHelper
