<template>
  <div class="home-view">
    <div class="home-view__title"></div>
    <div class="home-view__content">
      <proximos-partidos></proximos-partidos>
    </div>
  </div>
</template>

<script>
import ProximosPartidos from "@/components/ProximosPartidos/ProximosPartidos.vue";

export default {
  name: 'home-view',
  components: {ProximosPartidos},
  props: [],
  mixins: [],
  data() {
    return {}
  },
  mounted() {

  },
  methods: {},
  computed: {}
}
</script>
<style lang="scss">
.home-view {

  &__title {

  }

  &__content {

  }
}

/* Use a media query to add a breakpoint at 800px: */
@media screen and (max-width: 800px) {

  .home-view {
    &__title {

    }

    &__content {

    }
  }
}

</style>

