const hasHttpStaging = process.env.VUE_APP_FEATURE_HAS_HTTPS_STAGING

function urlTrack () {
    return {
        stagingUrl: process.env.VUE_APP_API_URL_STAGING,
        productionUrl: process.env.VUE_APP_API_URL,
        demoUrl: process.env.VUE_APP_API_URL_DEMO,
        demoDomain: process.env.VUE_APP_API_DOMAIN_DEMO,
        localUrl: localUrlFromPort('8000')

    }
}

export function computeApiUrl (urlObject) {

    if (window.location.href.indexOf('localhost') >= 0 || window.location.href.indexOf('192.168') >= 0 ||
        window.location.href.indexOf('10.0' || window.location.href.indexOf('172.20') >= 0) >= 0) {
        return urlObject.localUrl
    } else if (window.location.href.indexOf('staging') >= 0 || window.location.href.indexOf('172.16.0.223') >= 0 ||
        window.location.href.indexOf('172.') >= 0 ||
        window.location.href.indexOf('01des') >= 0) {
        if (hasHttpStaging === 'true') {
            if (window.location.href.indexOf('http:') >= 0) {
                let host = window.location.host
                window.location.replace('https://' + host)
            }
        }

        return urlObject.stagingUrl
    } else if (urlObject.demoUrl && urlObject.demoDomain && window.location.href.indexOf(urlObject.demoDomain) >= 0) {
        return urlObject.demoUrl
    } else {
        if (window.location.href.indexOf('http:') >= 0) {
            let host = window.location.host
            window.location.replace('https://' + host)
        }
        return urlObject.productionUrl
    }
}

function apiUrl () {
    return computeApiUrl(urlTrack())
}


function localUrlFromPort (port) {
    return `http://${window.location.hostname}:${port}/api/`
}

export default function () {
    return apiUrl()
}


