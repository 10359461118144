import store from '../store'

const Rights = {

  install (Vue, options) {
    // 4. add an instance method
    Vue.prototype.$estimtrackLoading = function () {
      return store.getters.getLoading
    }
    Vue.prototype.$loadingOn = function (){
      store.dispatch('incrementLoading')
    }

    Vue.prototype.$loadingOff = function (){
      store.dispatch('decrementLoading')
    }

    Vue.prototype.$loadingClear = function (){
      store.dispatch('clearLoading')
    }
  }
}

export default Rights
