import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './index.css'

Vue.config.productionTip = false

import estimtrackLoading from './plugins/Loading'
import TextHelper from './plugins/TextHelper'
import Rights from './plugins/Rights'
import vumonAxios from './plugins/VumonAxios'


Vue.use(estimtrackLoading)
Vue.use(TextHelper)
Vue.use(Rights)
Vue.use(vumonAxios)



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
